var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page",attrs:{"id":"page"}},[_c('heade',{attrs:{"active":3},on:{"change":_vm.changeTop}}),_c('div',{staticStyle:{"height":"64px"}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"menu"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"list"},[_c('div',{staticClass:"title",on:{"click":function($event){return _vm.changeType(item)}}},[_vm._v(_vm._s(item.title))]),_vm._l((item.list),function(ele,i){return _c('div',{key:i,staticClass:"list-item",on:{"click":function($event){return _vm.changeInfo(ele.id, index, i)}}},[(_vm.activeIndex == ele.id)?_c('div',{staticClass:"line"}):_vm._e(),_c('div',{staticClass:"name",style:({
                color: _vm.activeIndex == ele.id ? '#0f3391' : '#2c2c2c',
                fontWeight: _vm.activeIndex == ele.id ? '700' : '400',
              })},[_vm._v(" "+_vm._s(ele.name)+" ")])])}),(index != _vm.list.length - 1)?_c('div',{staticClass:"line"}):_vm._e()],2)}),0),_c('div',{staticClass:"details"},_vm._l((_vm.currentList),function(item,index){return _c('div',{key:index,staticClass:"details-item"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":item.url,"alt":""}})]),_c('div',{staticClass:"left"},[_c('div',{staticClass:"left-name",style:({
                height: item.name2H ? '49px' : '29px',
              })},[_vm._v(" 产品名称 ")]),_c('div',{staticClass:"left-name",style:({
                height: item.local2H ? '49px' : '29px',
              })},[_vm._v(" 多重定位 ")]),(item.ele)?_c('div',{staticClass:"left-name"},[_vm._v(" "+_vm._s(item.threeName || "电池电量")+" ")]):_vm._e(),_c('div',{staticClass:"left-name"},[_vm._v("产品特色")])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"text",style:({
                height: item.name2H ? '49px' : '29px',
              })},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"text",style:({
                height: item.local2H ? '49px' : '29px',
              })},[_vm._v(" "+_vm._s(item.local)+" ")]),(item.ele)?_c('div',{staticClass:"text"},[_vm._v(_vm._s(item.ele))]):_vm._e(),_c('div',{staticClass:"specialList",style:({ height: item.height })},_vm._l((item.specialList),function(ele,i){return _c('div',{key:i,staticClass:"specialList-text"},[_vm._v(" "+_vm._s(ele)+" ")])}),0)])])}),0)])]),[_c('bottom')]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }