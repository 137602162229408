<template>
  <div class="page" id="page">
    <heade :active="3" @change="changeTop"></heade>
    <div style="height: 64px"></div>
    <div class="content">
      <div class="main">
        <!-- 左边菜单 -->
        <div class="menu">
          <div v-for="(item, index) in list" :key="index" class="list">
            <div class="title" @click="changeType(item)">{{ item.title }}</div>
            <div
              v-for="(ele, i) in item.list"
              :key="i"
              class="list-item"
              @click="changeInfo(ele.id, index, i)"
            >
              <div class="line" v-if="activeIndex == ele.id"></div>
              <div
                class="name"
                :style="{
                  color: activeIndex == ele.id ? '#0f3391' : '#2c2c2c',
                  fontWeight: activeIndex == ele.id ? '700' : '400',
                }"
              >
                {{ ele.name }}
              </div>
            </div>
            <div class="line" v-if="index != list.length - 1"></div>
          </div>
        </div>
        <!-- 右边详情 -->
        <div class="details">
          <div
            v-for="(item, index) in currentList"
            :key="index"
            class="details-item"
          >
            <!-- {{item.name}} -->
            <div class="img">
              <img :src="item.url" alt="" />
            </div>
            <div class="left">
              <div
                class="left-name"
                :style="{
                  height: item.name2H ? '49px' : '29px',
                }"
              >
                产品名称
              </div>
              <div
                class="left-name"
                :style="{
                  height: item.local2H ? '49px' : '29px',
                }"
              >
                多重定位
              </div>
              <div class="left-name" v-if="item.ele">
                {{ item.threeName || "电池电量" }}
              </div>
              <div class="left-name">产品特色</div>
            </div>
            <div class="right">
              <div
                class="text"
                :style="{
                  height: item.name2H ? '49px' : '29px',
                }"
              >
                {{ item.name }}
              </div>
              <div
                class="text"
                :style="{
                  height: item.local2H ? '49px' : '29px',
                }"
              >
                {{ item.local }}
              </div>
              <div class="text" v-if="item.ele">{{ item.ele }}</div>
              <div class="specialList" :style="{ height: item.height }">
                <div
                  v-for="(ele, i) in item.specialList"
                  :key="i"
                  class="specialList-text"
                >
                  {{ ele }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 首页 -->
    <template>
      <bottom></bottom>
    </template>
  </div>
</template>

<script>
import bottom from "./bottom.vue";
import heade from "./header.vue";

export default {
  components: { heade, bottom },
  data() {
    return {
      activeIndex: 1,
      currentList: [],
      list: [
        {
          title: "人员定位终端",
          list: [
            {
              name: "穿戴产品",
              id: 1,
              detailsList: [
                {
                  url: require("../assets/images/product/person1.png"),
                  name: "4G智能电子工牌",
                  local: "北斗/GPS/LBS",
                  ele: "1000mAh",
                  local2H: true,
                  height: "130px",

                  specialList: [
                    "双向通话 SOS呼叫",
                    "语音报时",
                    "TTS语音播报",
                    "远程打卡",
                    "低电报警",
                  ],
                },
                {
                  url: require("../assets/images/product/person2.png"),
                  name: "4G智能电子工牌",
                  local: "北斗/GPS/LBS",
                  ele: "2000mAh",
                  local2H: true,
                  height: "130px",

                  specialList: [
                    "双向通话 SOS呼叫",
                    "超长待机 ",
                    "TTS语音播报",
                    "蓝牙打卡",
                    "低电报警",
                  ],
                },
                {
                  url: require("../assets/images/product/person3.png"),
                  name: "4G智能电子工牌",
                  local: "北斗/GPS/LBS",
                  local2H: true,
                  height: "130px",

                  ele: "1500mAh",
                  specialList: [
                    "双向通话",
                    "SOS呼叫",
                    "超长待机",
                    "TTS语音播报",
                    "远程打卡",
                    "低电报警",
                  ],
                },
                {
                  url: require("../assets/images/product/person6.png"),
                  name: "智能电子肩灯",
                  local: "北斗/GPS/Wi-Fi/AGPS/蓝牙/基站",
                  ele: "1800mAh",
                  height: "130px",

                  local2H: true,
                  specialList: [
                    "作业位置实时查看",
                    "远程考勤打卡",
                    "4G全网通",
                    "IP66防尘水",
                    "警示爆闪",
                    "TTS语音播报",
                  ],
                },

                {
                  url: require("../assets/images/product/person5.png"),
                  name: "穿戴产品",
                  local: "北斗/GPS/Wi-Fi/LBS/AGPS",
                  ele: "550mAh",
                  local2H: true,
                  height:"130px",

                  specialList: [
                    "双向通话 SOS呼叫",
                    "语音报时",
                    "TTS语音播报",
                    "低电报警",
                  ],
                },
                {
                  url: require("../assets/images/product/person4.png"),
                  name: "穿戴产品",
                  local: "GPS/LBS/AGPS",
                  ele: "570mAh",
                  local2H: true,
                  height:"130px",

                  specialList: [
                    "双向通话 SOS呼叫",
                    "语音报时",
                    "TTS语音播报",
                    "低电报警",
                    "超长待机",
                    "IP68防尘水",
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "车载定位终端",
          list: [
            {
              name: "超长待机",
              id: 2,
              detailsList: [
                {
                  url: require("../assets/images/product/car1.png"),
                  name: "超长待机定位终端",
                  local: "北斗/GPS/WIFI/LBS",
                  threeName: "待机时间",
                  height:"50px",
                  local2H: true,
                  ele: "三年以上",
                  specialList: ["光感防拆  低电报警", "强磁吸附  安装隐蔽"],
                },
                {
                  url: require("../assets/images/product/car2.png"),
                  name: "超长待机定位终端",
                  local2H: true,
                  local: "北斗/GPS/WIFI/LBS",
                  ele: "三年以上",
                  height:"50px",

                  specialList: ["光感防拆  低电报警", "强磁吸附  安装隐蔽"],
                },
                {
                  url: require("../assets/images/product/car3.png"),
                  name: "4G水箱定位终端",
                  threeName: "待机时间",
                  height:"50px",

                  local: "LBS/Wi-Fi",
                  threeName: "待机时间",
                  local2H: true,
                  ele: "两年",
                  specialList: ["低电报警  超小机身", "IP68防护等级"],
                },
              ],
            },
            {
              name: "有线产品",
              id: 3,
              detailsList: [
                {
                  url: require("../assets/images/product/car21.png"),
                  name: "小型车载定位终端",
                  local: "北斗/GPS//LBS",
                  ele: "",
                  height:"130px",

                  local2H: true,
                  specialList: [
                    "体积小巧",
                    "驾驶行为监测",
                    "远程断电",
                    "外电低电保护",
                  ],
                },
                {
                  url: require("../assets/images/product/car22.png"),
                  name: "车载定位终端",
                  local: "北斗/GPS/LBS",
                  ele: "",
                  height:"130px",

                  local2H: true,
                  specialList: [
                    "体积小巧",
                    "驾驶行为监测",
                    "远程断电",
                    "外电低电保护",
                    "电子围栏  超速报警",
                    "低电报警  拆除报警",
                  ],
                },
                {
                  url: require("../assets/images/product/car23.png"),
                  name: "车载定位终端",
                  local: "北斗/GPS/LBS/  AGPS",
                  ele: "",
                  height:"130px",

                  local2H: true,
                  specialList: [
                    "体积小巧",
                    "防伪基站",
                    "防探测  震动报警",
                    "超速报警",
                    "反接保护 短路保护",
                  ],
                },
                {
                  url: require("../assets/images/product/car24.png"),
                  name: "OBD智能车载盒子",
                  local: "北斗/GPS",
                  ele: "",
                  height:"130px",

                  local2H: true,
                  specialList: [
                    "小巧轻薄",
                    "OBD即插即用",
                    "4G全网通",
                    "驾驶行为分析",
                    "OBD诊断",
                    "异常报警",
                  ],
                },
                {
                  url: require("../assets/images/product/car25.png"),
                  name: "OBD车载定位终端",
                  local: "北斗/GPS/LBS",
                  ele: "",
                  height:"165px",

                  local2H: true,
                  specialList: [
                    "小巧轻薄",
                    "OBD即插即用",
                    "双星定位",
                    "4G通信",
                    "驾驶行为分析",
                    "异常震动",
                    "进出围栏",
                    "超速报警",
                  ],
                },
                {
                  url: require("../assets/images/product/car26.png"),
                  name: "点烟器车载定位终端",
                  local: "北斗/GPS",
                  ele: "",
                  local2H: true,
                  height:"165px",

                  specialList: [
                    "双星定位",
                    "即插即用",
                    "双USB充电口",
                    "破窗器",
                    "驾驶行为分析",
                    "超速报警",
                    "电子围栏",
                    "设防/撤防",
                  ],
                },
                {
                  url: require("../assets/images/product/car27.png"),
                  name: "车载定位终端",
                  local: "北斗/GPS/LBS",
                  height:"165px",

                  ele: "",
                  local2H: true,
                  specialList: [
                    "4G CAT1",
                    "远程OTA",
                    "电子围栏",
                    "盲区补传",
                    "超速报警",
                    "宽电压9-90V",
                  ],
                },
                {
                  url: require("../assets/images/product/car28.png"),
                  name: "OBD车载定位终端",
                  local: "北斗/GPS/LBS",
                  ele: "",
                  height:"165px",

                  local2H: true,
                  specialList: [
                    "小巧轻薄",
                    "OBD即插即用",
                    "断电报警",
                    "震动报警",
                    "电瓶低电报警",
                    "拆卸报警",
                    "超速报警",
                  ],
                },
              ],
            },
            {
              name: "视频产品",
              id: 4,

              detailsList: [
                {
                  url: require("../assets/images/product/car31.png"),
                  name: "（主动安全）视频部标机",
                  local: "北斗/GPS",
                  ele: "",
                  name2H: true,
                  threeName: "待机时间",
                  height:"230px",

                  specialList: [
                    "⽀持81080P/720P AHD摄像头支持2张SD卡/单卡256G存储",
                    "支持1个机械硬盘（7/9mm）/固态硬盘（最大2T）支持ADAS/DSM/BSD",
                  ],
                },
                {
                  url: require("../assets/images/product/car32.png"),
                  name: "智能车载终端",
                  local: "北斗/GPS",
                  name2H: true,
                  ele: "",
                  threeName: "待机时间",
                  height:"230px",

                  specialList: [
                    "支持2路视频编解码（1080P+720P）",
                    "支持ADAS、DSM预警SD卡支持 1 张 TF 卡/单卡 512G 存储内置MIC和喇叭，支持对讲",
                  ],
                },
                {
                  url: require("../assets/images/product/car33.png"),
                  name: "视频监控终端",
                  local: "北斗/GPS",
                  ele: "",
                  name2H: true,
                  height:"230px",

                  // threeName: "待机时间",
                  specialList: [
                    "语音对讲",
                    "最大256G存储",
                    "人脸识别 前向碰撞",
                    "声光报警器/传感器",
                    "掉电报警  欠压报警",
                    "故障跟踪",
                    "防水防尘防雾 自主识别",
                    "人员、挖臂、",
                    "车辆、水等异常物",
                    "体进入货箱",
                  ],
                },
                {
                  url: require("../assets/images/product/car34.png"),
                  name: "视频监控终端",
                  local: "北斗/GPS",
                  threeName: "待机时间",
                  height:"230px",

                  name2H: true,
                  ele: "",
                  specialList: [
                    "高精度定位",
                    "⼀键报警",
                    "最大512G 存储",
                    "1路 720P 录像",
                    "⽋压/短路/反接等",
                    "电路保护",
                  ],
                },
                {
                  url: require("../assets/images/product/car35.png"),
                  name: "4G双摄行车记录仪",
                  local: "北斗/GPS",
                  ele: "",
                  height:"160px",

                  threeName: "待机时间",
                  specialList: [
                    "智能双录、DSM检",
                    "测（选配）",
                    "实时视频",
                    "远程拍照／视频",
                    "驾驶行为分析",
                    "一键求救",
                    "支持单TF卡最大256GB",
                  ],
                },
                {
                  url: require("../assets/images/product/car36.png"),
                  name: "4G智能行车记录仪",
                  local: "北斗/GPS",
                  threeName: "待机时间",
                  height:"160px",

                  ele: "",
                  specialList: [
                    "驾驶行为分析",
                    "实时监控",
                    "支持部标",
                    "体积小巧易安装",
                    "事件告警",
                  ],
                },
                {
                  url: require("../assets/images/product/car37.png"),
                  name: "4G多摄行车记录仪",
                  local: "北斗/GPS",
                  threeName: "待机时间",
                  ele: "",
                  height:"160px",

                  specialList: [
                    "最大支持4路摄像头",
                    "支持ADAS、DSM",
                    "预警",
                    "支持双TF卡最大",
                    "256GB*2",
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "资产定位终端",
          list: [
            {
              name: "便携可充电无线产品",
              id: 5,
              detailsList: [
                {
                  url: require("../assets/images/product/a1.png"),
                  name: "4G太阳能可充电定位终端",
                  local: "北斗/GPS",
                  height:"130px",

                  name2H: true,
                  ele: "",
                  specialList: [
                    "太阳能/磁吸充电",
                    "车辆状态检测",
                    "IP67防尘防水",
                    "防拆/震动报警",
                    "RFID识别",
                    "蓝牙外扩",
                  ],
                },
                {
                  url: require("../assets/images/product/a2.png"),
                  name: "4G卡片式可充电定位终端",
                  local: "北斗/GPS",
                  ele: "",
                  height:"130px",

                  name2H: true,
                  specialList: [
                    "多种工作模式",
                    "低电报警",
                    "拆箱报警",
                    "温湿度监测",
                  ],
                },
                {
                  url: require("../assets/images/product/a3.png"),
                  name: "便携可充电无线定位终端",
                  local: "北斗/GPS",
                  ele: "",
                  name2H: true,
                  height:"130px",

                  specialList: [
                    "强磁吸附",
                    "光感防拆低电报警",
                    "超长待机声控报警",
                    "震动/超速报警",
                    "IP65防尘防水",
                  ],
                },
                {
                  url: require("../assets/images/product/a4.png"),
                  name: "卡片式可充电定位终端",
                  local: "北斗/GPS",
                  ele: "",
                  name2H: true,
                  height:"130px",

                  specialList: [
                    "开箱检测",
                    "温湿度检测",
                    "开门检测 电量显示",
                    "室内外定位",
                    "暴力运输检查",
                    "远程OTA升级",
                  ],
                },
                {
                  url: require("../assets/images/product/a5.png"),
                  name: "卡片式可充电定位终端",
                  local: "北斗/GPS",
                  ele: "",
                  name2H: true,
                  height:"90px",

                  specialList: [
                    "智能省电 异常告警",
                    "防拆设计 强磁吸附",
                    "电子围栏 声控录音",
                    "远程拾音",
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "其他",
          list: [
            {
              name: "电摩产品",
              id: 6,
              detailsList: [
                {
                  url: require("../assets/images/product/other1.png"),
                  name: "4G电动车485智能中控",
                  local: "北斗/GPS",
                  ele: "",
                  height:"165px",
                  name2H: true,
                  specialList: [
                    "4G通讯 多重定位",
                    "驾驶行为监测",
                    "远程断电 电子围栏",
                    "小巧升级 多重报警",
                    "超宽幅电压",
                  ],
                },
                {
                  url: require("../assets/images/product/other2.png"),
                  name: "电动车团标云盒",
                  local: "北斗/GPS",
                  ele: "",
                  name2H: true,
                  height:"165px",
                  specialList: [
                    "电池组与整车协议",
                    "互认",
                    "电池组管理系统",
                    "（BMS）",
                    "过温报警",
                    "BMS数据上报功能",
                    "Cat.1通讯",
                    "9-90V宽电压输入",
                  ],
                },
              ],
            },
          ],
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.currentList = this.list[0].list[0].detailsList;
    let data = sessionStorage.getItem("info")
      ? JSON.parse(sessionStorage.getItem("info"))
      : {};
    if (data) {
      this.changeTop(data);
      sessionStorage.clear();
    }
  },
  destroyed() {
    localStorage.setItem("prodId", 1);
  },
  methods: {
    changeTop(data) {
      let id = data.id;
      let index = data.index;
      let i = data.i;
      this.changeInfo(id, index, i);
    },
    changeInfo(id, index, i) {
      this.currentList = this.list[index].list[i].detailsList;
      this.activeIndex = id;
      localStorage.setItem("prodId", id);
    },
    changeType(item) {
      this.currentList = item.list[0].detailsList;
      this.activeIndex = item.list[0].id;
      console.log(item);
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  width: 100vw;
  background-color: #fff;
  .main {
    width: 1200px;
    min-width: 1200px;
    min-height: calc(100vh - 606px);
    height: auto;
    margin: 0 auto;
    // background-color: pink;
    display: flex;
    justify-content: space-between;
  }
}
.menu {
  width: 200px;
  background-color: #fafafa;
  padding-top: 56px;
  padding-left: 12px;
  box-sizing: border-box;
  min-height: calc(100vh - 606px);
  height: auto;
  .line {
    width: 176px;
    height: 0px;
    border: 1px solid #dbdbdb;
    margin-bottom: 16px;
  }
  .list {
    .title {
      margin-bottom: 24px;
      font-size: 16px;
      color: #2c2c2c;
      font-weight: 400;
      padding-left: 8px;
    }
    .list-item {
      display: flex;
      padding-left: 8px;
      .line {
        width: 4px;
        height: 12px;
        background: #0f3391;
        border-radius: 3px 3px 3px 3px;
        margin-right: 8px;
      }
      .name {
        font-weight: 300;
        font-size: 14px;
        color: #2c2c2c;
        line-height: 12px;
        margin-bottom: 16px;
        cursor: pointer;
      }
    }
  }
}
.details {
  width: 960px;
  min-height: 1000px;
  display: flex;
  flex-wrap: wrap;
  .details-item {
    width: 222px;
    margin-right: 24px;
    margin-bottom: 48px;
    .left {
      width: 80px;
      .left-name {
        width: 80px;
        height: 29px;
        background-color: #8197b5;
        line-height: 29px;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        margin-top: 8px;
      }
    }
    .right {
      margin-left: 8px;
      .text {
        width: 134px;
        height: 29px;
        background: #fafafa;
        font-weight: 300;
        font-size: 14px;
        color: #2c2c2c;
        line-height: 29px;
        margin-top: 8px;
        padding-left: 8px;
        box-sizing: border-box;
      }
      .specialList {
        width: 134px;
        background: #fafafa;
        height: 180px;
        font-weight: 300;
        margin-top: 8px;
        font-size: 14px;
        padding: 5px 8px 0px 8px;
        box-sizing: border-box;
      }
      .specialList-text {
      }
    }
  }
  .details-item:nth-child(4n) {
    margin-right: 0px;
  }
  .img {
    width: 222px;
    height: 224px;
    background-color: #f8f8f8;
    margin-bottom: 16px;
  }
  img {
    width: 222px;
    height: 224px;
  }
}
</style>
